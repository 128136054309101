/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useContext, useRef, useState } from "react"
import { dashboardTrail } from "../../utils/dashboardBreadcrumb"
import LayoutDashborad from "../../components/Layout/Dashboard"
import BreadCrumb from "../../components/Elements/BreadCrumb"
import DownloadTable from "../../components/Table/MyListing/AssetsTable"
import {
  deleteMySingleForm,
  getBrandSingle,
  AuditPostHog,
  getMyListing,
  getMyListingPagination,
  getMySingleForm,
} from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
import FloatingSelect from "../../components/Elements/Form/FloatingSelect"
import AssetsTable from "../../components/Table/MyListing/AssetsTable"
import DynamicForm from "../../components/Forms/DynamicForm/DynamicForm"
import EditFormTemplate from "../../components/Forms/EditFormTemplate"
import Modal from "../../components/Modal/Modal"
import SectionCard from "../../components/Cards/Dashboard/SectionCard"
import Button from "../../components/Elements/Button"
import AuditTable from "../../components/Table/MyListing/AuditTable"
import NotificationContext from "../../context/Notification/NotificationContext"
import TableFilter from "../../components/Table/TableFilter"
import Skeleton from "../../components/Skeleton"
import { parse } from "query-string"
import Pagination from "../../components/Pagination"
import DeleteConfirm from "../../components/Forms/DeleteConfirm"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import AccessDenied from "../../components/AccessDenied"
import { data } from "autoprefixer"
import { set } from "date-fns"
import Select from "react-select"
import {
  selectStyles,
  selectStylesVisualLabel,
  selectStylesSmall,
  // SelectContainer,
  DropdownIndicator,
  ClearIndicator,
} from "../../styled/Select"

function MyAssets({ location }) {
  const { userType } = useContext(AuthenticationContext)

  const { state } = useContext(StateContext)
  const [list, setList] = useState(null)

  const [pageNumber, setPageNumber] = useState(0)
  const [totalPage, setTotalPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [hasMore, setHasMore] = useState(false)
  const { currentUserData } = useContext(AuthenticationContext).authentication
  const [companyIds, setCompanyIds] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(companyIds[0] || null);
  const [showPagination, setShowPagination] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [list]);
  useEffect(() => {
    const ids = currentUserData?.companies?.map((company) => company.id) || [];
    setCompanyIds(ids);

    if (ids.length > 0 && !selectedCompany) {
      setSelectedCompany({
        value: ids[0],
        label: currentUserData?.companies?.find((company) => company.id === ids[0])?.title || `Company ${ids[0]}`,
      });
    }
  }, [currentUserData]);

  const options = currentUserData?.companies?.map((company) => ({
    value: company.id,
    label: company.title,
  }));

  useEffect(() => {
    if (selectedCompany) {
      const fetchInitialData = async () => {
       
        const offset = 0
        const currentPageData = await AuditPostHog(state.token, selectedCompany?.value, offset,10);
  
        setList(currentPageData);
        console.log(list,'list')
      };
  
      fetchInitialData();
    }
  }, [selectedCompany,currentUserData]);
  
  const onPageChange = async () => {
    const nextPage = page + 1;
    const offset = nextPage * 10;

    setPage(nextPage);

    console.log(offset,'offset')
    setList(null);
    setLoading(true);
    const currentPageData = await AuditPostHog(state.token, selectedCompany?.value, offset,10);
   
    setList(currentPageData);
    setLoading(false);
   const nextPage2 = (page + 1) * 10;
   console.log(nextPage2,'nextPage2')

    const nextPageData = await AuditPostHog(state.token, selectedCompany?.value, nextPage2,1);
    console.log(nextPageData,'nextPage')
    setHasMore(nextPageData.length > 0 ? true : false);


  };

  const onPageBack = async () => {
    const nextPage = page - 1;
    const offset = nextPage * 10;

    setPage(nextPage);

    console.log(offset,'offset of prev')
    setList(null);
    setLoading(true);
    const currentPageData = await AuditPostHog(state.token, selectedCompany?.value, offset,10);
   
    setList(currentPageData);
    setLoading(false);
    setHasMore(false)
   
  //  const nextPage2 = (page + 1) * 10;
  //  console.log(nextPage2,'nextPage2')

  //   const nextPageData = await AuditPostHog(state.token, selectedCompany?.value, nextPage2,1);
  //   console.log(nextPageData,'nextPage')
  //   setHasMore(nextPageData.length > 0 ? true : false);
   
  };

  console.log(page,'page')

  const loadRef = useRef()
  const handleChange = (selectedOption) => {
    setSelectedCompany(selectedOption);
  };

  if (!userType.brandManager) return <AccessDenied />

  return (
    <LayoutDashborad heading={`Audit Log`} trail={dashboardTrail}
    >

      <div className="w-full max-w-[300px] pb-8">
        {/* <label className="block text-gray-700 mb-2">Choose company</label> */}
        <Select
          options={options}
          value={selectedCompany}
          onChange={handleChange}
          styles={selectStyles}
          components={{
            DropdownIndicator,
            ClearIndicator,
          }}
          placeholder="Select a company"
          isSearchable={false} // Disable typing
        />
      </div>

      {list === null ? (
        <Skeleton table />
      ) : list.length > 0 ? (
        <AuditTable data={list} />
      ) : (
        "No more logs to display"
      )}
      <div ref={loadRef} />
      {showPagination && (
        <>
          <div className="mx-auto flex justify-between space-x-4 py-4">
            <button
              onClick={onPageBack}
              disabled={page === 0}
              className="px-8 py-2 text-white rounded-[8px]"
              style={{
                backgroundColor: page === 0 ? "grey" : "#E43D30",
                fontFamily: "KansasNew",
                fontWeight: "bold",
              }}
            >
              Prev
            </button>

            <button
              onClick={onPageChange}
              disabled={list === null || list.length === 0}
              className="px-8 py-2 text-white rounded-[8px] ml-auto"
              style={{
                backgroundColor: list === null || list.length === 0 ? "grey" : "#E43D30",
                fontFamily: "KansasNew",
                fontWeight: "bold",
              }}
            >
              Next
            </button>
          </div>
        </>
      )}
      {!showPagination && hasMore ? (
        <div className="w-full overflow-hidden flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
        </div>
      ) : (
        <p className="text-reg-14"></p>
      )}
    </LayoutDashborad>
  )
}

export default MyAssets
